.img-missing-text {
  position: relative;
  background-color: #eee;
  width: 100%;
}
.img-missing-text::after {
  content: "Image missing";
  position: absolute;
  color: #aaa;
}

