@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';
@import '../variables.scss';

.contact-social > :not(:last-child) {
  margin-right: $grid-gap-default;
}

@include media-breakpoint-down(sm) {
  .contact-content {
    flex-direction: column;

    > * {
      margin: $grid-gap-default;
    }
  }
}
