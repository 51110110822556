strong,
.font-weight-semibold {
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .navbar.fixed-top-sm {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-top-sm-spacer {
    height: 56px;
  }
}
.navbar-brand svg {
  line-height: 30px;
  height: 30px;
  margin-right: 10px;
  fill: #414542;
}

.nav-link.active {
  font-weight: 500;
}

