@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';
@import 'variables';

.about-page {
  h2,
  .contact-button {
    margin-top: 4rem;
  }

  .img-hero {
    display: flex;
    justify-content: center;

    img {
      width: 50%;
      height: 50%;
      max-width: 700px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .about-page {
    .img-hero img {
      width: 100%;
      height: 100%;
    }
  }
}

@include media-breakpoint-down(xs) {
  .about-page {
    h2,
    .contact-button {
      margin-top: 2rem;
    }
  }
}
