strong,
.font-weight-semibold {
  font-weight: 600;
}

.contact-social > :not(:last-child) {
  margin-right: 20px;
}

@media (max-width: 767.98px) {
  .contact-content {
    flex-direction: column;
  }
  .contact-content > * {
    margin: 20px;
  }
}

