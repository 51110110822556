strong,
.font-weight-semibold {
  font-weight: 600;
}

.App .card-list {
  display: flex;
  flex-grow: 1;
  flex-flow: row wrap;
  padding: 7.5px;
  margin: -15px;
  align-content: flex-start;
}
@media (max-width: 767.98px) {
  .App .card-list {
    justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .App .card-list .button-link.button-card {
    flex-basis: 45%;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .App .card-list .button-link.button-card {
    flex-basis: 30%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .App .card-list .button-link.button-card {
    flex-basis: 22%;
  }
}
@media (min-width: 1200px) {
  .App .card-list .button-link.button-card {
    flex-basis: 18%;
  }
}
.App .card-list .button-link.button-card {
  padding: 0;
  cursor: pointer;
  margin: 7.5px;
  border: 1px solid #c1c4c2;
  border-radius: calc(0.5rem - 1px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  transition: all 0.3s;
}
.App .card-list .button-link.button-card:hover, .App .card-list .button-link.button-card:focus {
  outline: none;
  box-shadow: 0 0.2rem 10px 0.1px #a840c5;
  border-color: #a840c5;
  transform: translate(0, -0.2rem);
}
.App .card-list .card-thumbnail {
  overflow: hidden;
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}
.App .card-list .card-thumbnail > * {
  width: 100%;
  height: 100%;
  min-height: 88px;
}
.App .card-list .card-body-wrapper {
  padding: 0.8rem;
  font-size: 0.8rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.App .card-list .card-body-wrapper .card-body-text {
  margin-bottom: 0.8rem;
}
.App .card-list .card-body-wrapper .card-body-text > :not(:last-child) {
  margin-bottom: 0.4rem;
}

