.checkbox-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
}
.checkbox-wrapper:not(:only-child) {
  margin-bottom: -8px;
}
.checkbox-wrapper .checkbox {
  margin-left: -10px;
  padding: 7px;
}

