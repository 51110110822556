@import 'colors';

.App-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary;
  height: 100px;
  padding: 20px;
  color: $dark;
}

.App-content {
  padding: 20px;
}

.App-title {
  font-size: 1.5rem;
}

.App-subtext {
  letter-spacing: 1.5px;
}
