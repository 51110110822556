$color-primary: #3fd170;
$color-complementary: #dd4001;
$color-secondary-1: #a840c5;
$color-secondary-2: #ffcb4d;
$color-gray-dark: #414542;
$color-gray-light: #f9fbfa;

$bg-gray: lighten($color-gray-dark, 65%);

// bootstrap overrides
$primary: $color-secondary-1;
$secondary: $color-gray-dark;
$light: $color-gray-light;
$dark: $color-gray-dark;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'light': $light,
  'dark': $dark,
);
