strong,
.font-weight-semibold {
  font-weight: 600;
}

.about-page h2,
.about-page .contact-button {
  margin-top: 4rem;
}
.about-page .img-hero {
  display: flex;
  justify-content: center;
}
.about-page .img-hero img {
  width: 50%;
  height: 50%;
  max-width: 700px;
}

@media (max-width: 767.98px) {
  .about-page .img-hero img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 575.98px) {
  .about-page h2,
  .about-page .contact-button {
    margin-top: 2rem;
  }
}

