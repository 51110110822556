strong,
.font-weight-semibold {
  font-weight: 600;
}

.search {
  background-color: #e8e9e8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  align-self: stretch;
  height: 75px;
  padding: 5px 20px;
}
.search .form-group {
  margin: 0;
  max-width: 600px;
  width: 100%;
}

