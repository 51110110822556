@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';
@import 'variables';

$card-radius: calc(0.5rem - 1px);
$card-border-radius: calc(0.5rem - 1px);

.App .card-list {
  display: flex;
  flex-grow: 1;
  flex-flow: row wrap;
  padding: $grid-gap-mobile / 2;
  margin: -$grid-gap-mobile;
  align-content: flex-start;

  @include media-breakpoint-between(xs, sm) {
    justify-content: center;
  }

  @include media-breakpoint-down(xs) {
    .button-link.button-card {
      flex-basis: 45%;
    }
  }
  @include media-breakpoint-between(sm, md) {
    .button-link.button-card {
      flex-basis: 30%;
    }
  }
  @include media-breakpoint-only(lg) {
    .button-link.button-card {
      flex-basis: 22%;
    }
  }
  @include media-breakpoint-up(xl) {
    .button-link.button-card {
      flex-basis: 18%;
    }
  }

  .button-link.button-card {
    padding: 0;
    cursor: pointer;
    margin: $grid-gap-mobile / 2;
    border: 1px solid lighten($dark, 50%);
    border-radius: $card-border-radius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    transition: all 0.3s;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0.2rem 10px 0.1px $primary;
      border-color: $primary;
      transform: translate(0, -0.2rem);
    }
  }

  .card-thumbnail {
    overflow: hidden;
    border-radius: $card-radius $card-radius 0 0;

    > * {
      width: 100%;
      height: 100%;
      min-height: 88px;
    }
  }

  .card-body-wrapper {
    padding: $card-padding-default;
    font-size: $font-size-default;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-body-text {
      margin-bottom: $card-padding-default;

      > :not(:last-child) {
        margin-bottom: ($card-padding-default / 2);
      }
    }
  }
}
