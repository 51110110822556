@charset "UTF-8";
strong,
.font-weight-semibold {
  font-weight: 600;
}

.product-info {
  display: grid;
}
@media (min-width: 768px) {
  .product-info {
    grid-template-rows: [header] 3rem [line2] 1fr [line3] 1fr;
    grid-template-columns: [image] 1fr [primary] 2fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
  }
  .product-info .info-image {
    grid-row: header/line3;
  }
  .product-info .info-list {
    grid-row: line2/last-line;
  }
}
@media (max-width: 767.98px) {
  .product-info {
    grid-template-rows: [header] 3rem [line2] auto [line3] 1fr;
    grid-template-columns: [primary image] 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 7.5px;
  }
  .product-info .info-image {
    grid-row: line2;
  }
  .product-info .info-list {
    grid-row: line3;
  }
}
.product-info .info-header {
  grid-column: primary;
  grid-row: header;
}
.product-info .info-image {
  grid-column: image;
}
.product-info .info-list {
  grid-column: primary;
}
.product-info img {
  width: 100%;
  height: 100%;
}
.product-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.product-info ul li {
  margin-bottom: 7px;
  padding-left: 16px;
}
.product-info ul li:before {
  content: "•"; /* Insert content that looks like bullets */
  padding-right: 8px;
  color: #aaa; /* Or a color you prefer */
}

.warning {
  background-color: rgba(221, 64, 1, 0.1);
}

