.mobile-filter-wrapper {
  padding: 6px 20px;
}

.mobile-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-filter-body {
  margin-top: 6px;
  padding-top: 15px;
  border-top: 1px solid #ccc;
}

