strong,
.font-weight-semibold {
  font-weight: 600;
}

.homepage-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #3fd170;
  height: auto;
  padding: 100px;
  color: #414542;
  background: radial-gradient(closest-side at 60% 45%, #f9fbfa, #3fd170);
}
.homepage-header button.btn-primary {
  margin-top: 40px;
}
.homepage-header .logo-container {
  max-width: 10rem;
}

.homepage-header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  color: #414542;
}
.homepage-header-text .App-title {
  font-size: 3em;
}

.home-content {
  overflow: hidden;
}
.home-content > *:not(:last-child) {
  margin-bottom: 100px;
}

.home-card-container {
  display: flex;
  justify-content: center;
  margin-left: -40px;
  margin-right: -40px;
  padding: 0 40px;
  background-image: url("./wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.home-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f9fbfa;
  box-shadow: #8c938e 0px 5px 40px -5px;
  width: 250px;
  padding: 20px;
  margin: 0 20px;
}
.home-card .description {
  margin: 10px 0;
  font-size: 1.2em;
}
.home-card .subtext {
  font-weight: 300;
  font-size: 0.9rem;
  margin: 0.85rem 0;
}
.home-card a {
  margin: 10px 0 0;
}

@media (max-width: 767.98px) {
  .home-content > *:not(:last-child) {
    margin-bottom: 15px;
  }
  .home-card-container {
    display: flex;
    justify-content: center;
    margin-left: -30px;
    margin-right: -30px;
    padding: 0 30px;
    background-size: auto;
  }
  .home-card {
    width: auto;
    flex-basis: 33%;
    padding: 15px;
    margin: 0 7.5px;
  }
  .home-card .description {
    font-size: 1em;
  }
  .homepage-header {
    padding: 45px 75px;
  }
  .homepage-header-text .App-title {
    font-size: 2em;
  }
  .logo-container {
    flex-basis: 30%;
  }
}
@media (max-width: 575.98px) {
  .home-card-container {
    flex-direction: column;
  }
  .home-card {
    max-width: 75%;
    margin: auto;
    margin-bottom: 15px;
  }
  .home-card.priority-item {
    order: 1;
  }
  .home-card:not(.priority-item) {
    order: 2;
  }
  .homepage-header {
    flex-direction: column;
    padding: 45px;
  }
  .homepage-header button.btn-primary {
    margin-top: 15px;
  }
  .homepage-header svg {
    height: 3em;
  }
  .homepage-header-text {
    align-items: center;
    padding: 15px;
  }
  .homepage-header-text .App-title {
    font-size: 1.5em;
  }
}
@media (min-width: 576px) {
  .logo-container {
    margin-right: 40px;
  }
}

