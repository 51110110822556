strong,
.font-weight-semibold {
  font-weight: 600;
}

.livestock-list-page {
  display: grid;
  grid-template-rows: [results] 50px [body] auto [end];
}
@media (min-width: 768px) {
  .livestock-list-page {
    grid-template-columns: [first] 240px [second] auto [end];
    grid-column-gap: 20px;
    grid-row-gap: 10px;
  }
  .livestock-list-page .results {
    grid-column: first/end;
  }
  .livestock-list-page .filter {
    grid-row: body;
    grid-column: first/second;
    align-self: start;
    margin-left: 20px;
  }
  .livestock-list-page .data {
    grid-column: second/end;
    padding-right: 20px;
  }
}
@media (max-width: 767.98px) {
  .livestock-list-page {
    grid-template-columns: [first] 1fr [second] auto [end];
    grid-row-gap: 7.5px;
  }
  .livestock-list-page .filter {
    grid-row: results;
    grid-column: second/end;
    justify-self: end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
  }
  .livestock-list-page .results {
    grid-column: first/second;
    justify-self: start;
  }
  .livestock-list-page .data {
    grid-column: first/end;
    padding: 0 15px;
  }
}
.livestock-list-page .results {
  grid-row: results;
  align-self: stretch;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.livestock-list-page .data {
  grid-row: body;
  align-self: stretch;
}

