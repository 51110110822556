@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';
@import '../../variables.scss';

.product-info {
  display: grid;

  @include media-breakpoint-up(md) {
    grid-template-rows: [header] 3rem [line2] 1fr [line3] 1fr;
    grid-template-columns: [image] 1fr [primary] 2fr;
    grid-column-gap: $grid-gap-default;
    grid-row-gap: $grid-gap-default / 2;

    .info-image {
      grid-row: header / line3;
    }

    .info-list {
      grid-row: line2 / last-line;
    }
  }

  @include media-breakpoint-down(sm) {
    grid-template-rows: [header] 3rem [line2] auto [line3] 1fr;
    grid-template-columns: [primary image] 1fr;
    grid-column-gap: $grid-gap-mobile;
    grid-row-gap: $grid-gap-mobile / 2;

    .info-image {
      grid-row: line2;
    }

    .info-list {
      grid-row: line3;
    }
  }

  .info-header {
    grid-column: primary;
    grid-row: header;
  }

  .info-image {
    grid-column: image;
  }

  .info-list {
    grid-column: primary;
  }

  img {
    width: 100%;
    height: 100%;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 7px;
      padding-left: 16px;

      &:before {
        content: '•'; /* Insert content that looks like bullets */
        padding-right: 8px;
        color: #aaa; /* Or a color you prefer */
      }
    }
  }
}

.warning {
  background-color: rgba(221, 64, 1, 0.1); //todo: use a variable
}
